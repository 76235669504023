<div class="container">
    <div class="card text-center">
        <div class="card-header h4" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
          Day2 Css Assignment
        </div>
        <div class="card-body body">

            <h3 class="display-3 text-center text-light">Curriculum Vitae</h3>
            <div class="table-responsive-sm">

          <table align="center" class="table border border-light mx-auto">
              <tr>
                  <td width="200px" bgcolor="lc2833" style="color:white" padding="20px">
                   
                      <img class= "img-fluid fix" src="../../assets/img/IMG_20200404_110806_088.jpg"  style="border-radius: 10%;" width="300px"  height="250" alt="">
                      
                      <h1> Personal Detail</h1> <hr><br>
                          <p class="p1 text-left">
                             <b>Name: </b> 
                              Parth J. Shah <br><br>
          
                          <b> Address:</b> 
                              "shanti-bhuvan",Near Navyug cinema Dhrangadhra-363310<br><br>
          
                              <b> Phone No: </b>
                              -9106641608<br><br>
          
                              <b> Email-Address:</b><br>
                              <a href="http://gmail.com"><span style="color:rgb(217, 231, 23)">parthshah889900@gmail</span></a><br>
                              
                          </p>
                          <h1> Skills</h1><hr>
                         
                              <ul class="text-left">
                                  <li> C and C++</li>
                                  <li> Html, Css and Javascript </li>
                                  <li>.net</li>
                                  <li>java</li>
                              </ul>
                         
          
                          <h1> Intrests </h1><hr>
                          <ul class="text-left"> 
                              <li> Hiking</li>
                              <li> Dancing </li>
                              <li> Football </li>
                              <li> cricket </li>
                              <li> watching series</li>
                          </ul>
          
                  </td>
                  <td  class="disturb" width="500px" bgcolor="white">
                      <h1>Parth Shah</h1><hr>
                      <small >Final year student seeking to find the opportunity to work as a front-end software engineer in a fun and challenging enviorement which
                          can encourage me to learn new skills as well as motivated by company to do my best.
                      </small>
          
                      <h2>Education</h2><hr>
                      <div class="table-responsive-sm">
                      <table class="table education" border="1 px solid">
                          <thead> 
                              <td>course</td>
                              <td>college</td>
                              <td>Marks</td>
                          </thead>
          
                          <tr>
                              <td>B.E Comuter Engineering</td>
                              <td>C.U.Shah computer Engineering</td>
                              <td>7.73 CGPA</td>
                          </tr>
          
                          <tr>
                              <td>12th Science</td>
                              <td>Kendriya Vidyalaya Dhrangadhra-363310</td>
                              <td>80%</td>
                          </tr>
          
                          <tr>
                              <td>10th Science</td>
                              <td>Kendriya Vidyalaya Dhrangadhra-363310</td>
                              <td>7.4 CGPA</td>
                          </tr>
                      </table> 
                    </div>
                   
                      <h2>Work Experience</h2><hr>
                      <small>
                          <h3>Coding Teacher</h3>
                          Little Blue and Yellow Learning Center <br>
                          Its a coding center where the chidren of grades 2nd to 12th goig to learn coding of various language.
                      </small>
          
                      <h2> Personal Projects</h2><hr>
                      <p> Here there is an one video which represent my whole project.<br>
                       Bidding Zone</p>
                      Its an wesite where peple can easily bid the products.
                      <video class="img-fluid" width="400" height="200" controls>
                          <source src="../../assets/img/project.mp4" type="video/mp4">
                          
                        Your browser does not support the video tag.
                        </video>
          
              
                  </td>
          
                  
              </tr>
          </table>
        </div>
          
                    

        
      <style>
        @media(max-width:600px)
        {
          video{
            width: 100px;
          }
          .table{
            margin: 5px;
            overflow-x: scroll;

          }
        }

      </style>




         
        </div>
      </div>
    </div>
    
    <div class="m-5"></div>
