<div class="container">
    <div class="card text-center">
        <div class="card-header h4" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
            Day3 Css Assignment
          </div>
        <div class="card-body">
            <h6 class="display-4 font-weight-bold font-italic text-light">Basic Layout</h6>
         <!-- basic layout -->
         <div id="wrapper">
            <div id="header">
                <h2 class="h3 text-center"><u>C.U SHAH UNIVERSITY OF ENGINEERING AND TECHNOLOGY</u></h2>
                <h3 class="text-center trust">Run by: Parth Charitable Trust</h3>
            </div>
            <div id="nav">
                <a class="atag" href="https://www.google.com/" target="_blank">Home</a>
                <a class="atag" href="https://www.cushahuniversity.ac.in/" target="_blank">About Us</a>
                <a class="atag" href="https://www.cushahuniversity.ac.in/contact_us.php"target="_blank">Contact US</a>
            </div>
            <div id="container">
                <div id="left" class="text-left"> <p class="head">Constituent colleges</p><hr color="black"><br>
                <ul>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of Engineering and technology</a></li><br>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of MCA</a></li><br>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of Pharmacy</a></li><br>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of Nursing</a></li><br>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of B.ED</a></li><br>
                    <li><a href="http://www.ccetvbt.org/"  target="_blank">C.U Shah college of Arts</a></li>
    
                </ul>
                
                
                </div>
                <div id="right">
                    <p class="head"> About University</p><hr color="black">
                   <p class="lead about font-weight-bold"> C. U. Shah University, established on 22nd April, 2013, managed-sponsored by Wardhman Bharti Trust (Established in 1968). This State Private University, wide-spread in across 75 acre land, recognized and approved by all regulatory and statutory bodies such as UGC (UGC Approval) AICTE PCI NCTE BCI MHRD INC and Govt. of Gujarat, runs many courses in various disciplines includes Engineering, Arts & Humanities, Commerce, Science & Life Science, Education, Management, Nursing, Pharmacy, Computer Science, Law and Social Works from diploma-UG-PG-Ph.D.. Many courses and colleges are NAAC accredited.
    The University aims for a clear understanding of small scale industry to medium scale industry to upper medium and large scale entrepreneurship for growth and development of the students and staff. The service to the society is the prime motive and motto of the University on all grounds of humanity as a whole through imparting quality education, hands on practices and research environment to the stake holders.<br>
  </p>              
  </div>
            </div>
            <div id="footer">
                <h4 class="h3">Copyright &copy; C.U.Shah University</h4>
            </div>
        </div>
    


        </div>
      </div>
    </div>
    
    <div class="m-5"></div>