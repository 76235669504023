<section>
    <div class="row no-gutters">
      <div class="col-md-3 bg-light p-0">
        <div class="list-group">
         
          <a href="#" class="list-group-item list-group-item-action disabled disable" tabindex="-1" aria-disabled="true">
           Assighnments
          </a>
          <a class="list-group-item list-group-item-action" (click)='showday12Js()'>Day12</a>
          <a class="list-group-item list-group-item-action" (click)='showday13Js()'>Day13</a>
          <a class="list-group-item list-group-item-action" (click)='showday14Js()'>Day14</a>
          <img class="img-fluid pt-5 mx-auto d-block rounded radix pb-5" src="../../assets/img/js.png" alt="" >

          
        </div>

      </div>
      <div class="col-md-9">
                
<router-outlet></router-outlet>

      </div>
    </div>
  </section>
   
