<div class="container">
    <div class="card">
        <div class="card-header h4" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
            Day1 Html Assignment
          </div>
        <div class="card-body">
<h2 class="display-4 text-center text-dark font-italic mb-2">Employee Registration Form</h2>

<div class="col p-4">

<div class="myDiv">
    <br>  
    <br>  
      
    <label> Employee Firstname: </label>         
    <input type="text" name="firstname" size="15" required> <br> <br>  
    <label> EmployeeMiddlename: </label>     
    <input type="text" name="middlename" size="15" required> <br> <br>  
    <label> Employee Lastname: </label>         
    <input type="text" name="lastname" size="15" required> <br> <br> 
    <label> Employee Age: </label>         
    <input type="number" name="age" size="2" required> <br> <br> 

    <label>   
        Gender :  
        </label><br>  
        <input type="radio" name="male" required> Male <br>  
        <input type="radio" name="female" required> Female <br>  
        <input type="radio" name="other" required> Other  
        <br>  
        <br> 
        <label>Designation </label>         
        <input type="text" name="Designation" size="15" required> <br> <br>
        <label> Salary </label>         
        <input type="text" name="salary" size="15" required> <br> <br> 

        <label>   
            Location :  
            </label>   
            <select>  
            <option value="Kutch">Kutch</option>  
            <option value="Dhrangadhra">Dhrangadhra</option>  
            <option value="Ahmedabad">Ahemdabad</option>  
            <option value="Gandhinagar">Gandhinagar</option>  
            <option value="Surendrangar">Surendranagar</option>  
            <option value="Rajkot">Rajkot</option>  
            <option value="Other">Other</option>  
            </select> 
            <br><br>  

            <label>
                
                Email:  
                </label>
                <input type="email" id="email" name="email" required> <br>    
                <br> <br>   
                
                <label>
                    Date of joining: 
                    </label>
                    <input type="date" id="date of joining" name="doj" required> <br>    
                    <br> <br>
                    Phone :  
    
                <input type="text" name="country code"  value="+91" size="2" required>   
                <input type="number" name="phone" size="10" required> <br> <br> 
                
              
                    <input type="submit" value="Submit">



</div>


</div>
</div>
</div>



</div>

