<section class="py-4 m-3 ">
  <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top py-1">
    <div class="container">
      <p>
            <img src="../assets/img/ng.png" alt="" height="50" width="50"><h3 class="d-inline align-middle text-light">
                ssignment Day16
            </h3>
          </p>
        <button class="navbar-toggler" data-toggle="collapse"
        data-target="#navbarNav"><span class="navbar-toggler-icon"></span></button>
        
        
        <div class="collapse navbar-collapse m-2 " id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" routerLink="/htmlFile" routerLinkActive="active">Html<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link"  routerLink="/cssFile" routerLinkActive="active">Css</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/JsFile" routerLinkActive="active">Javascript</a>
            </li>
           
          </ul>
        </div>
      </div>
     
      <h5 class="text-light h4">  Nested Routes
      </h5>
    
     
  </nav>
  </section>
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4 text-primary">Day wise Assignment with <span class="bg-warning">Nested Routes</span></h1>
      <p class="h4 bg-primary text-white p-1" >This is a day15 Assignment so here I have used nested routes.</p>
    </div>
  </div>

  <router-outlet></router-outlet> 