<div class="container">
    <div class="card text-center">
        <div class="card-header h4" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
            Day4 Css Assignment
          </div>
        <div class="card-body">
            <h1 class="display-4 text-light text-center">Photo Album</h1><hr>
                <p class="lead text-center text-warning"> <mark class="bg-primary text-white">Please resize the browser so you can see how its work </mark></p>
              
              
              <div class="row"> 
                <div class="column">
                  <img  class="img-fluid"  src="../../assets/images/13.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/2.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/4.jpg"style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/5.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/10.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/8.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/14.jpg"style="width:100%">
                </div>
                
                <div class="column">
                  <img  class="img-fluid" src="../../assets/images/14.jpg"style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/13.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/15.jpg"style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/11.jpg"style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/10.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/12.jfif" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/5.jpg" style="width:100%">
                </div> 
                 
                <div class="column">
                  <img class="img-fluid"  src="../../assets/images/4.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/2.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/11.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/3.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/13.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/11.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/8.jpg" style="width:100%">
                </div>
                
                <div class="column">
                  <img  class="img-fluid" src="../../assets/images/10.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/11.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/5.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/3.jpg" style="width:100%">
                  <img class="img-fluid"  src="../../assets/images/4.jpg" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/12.jfif" style="width:100%">
                  <img  class="img-fluid" src="../../assets/images/2.jpg" style="width:100%">
                </div>
              </div>
              

         
        </div>
      </div>
    </div>
    
    <div class="m-5"></div>