<div class="container">
    <div class="card text-center">
        <div class="card-header h4" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
            Day13 Js Assignment
          </div>
        <div class="card-body">
            <h1 class="display-4 text-primary text-center">Javascript date</h1>

            <div class="demo  pt-10px pb-10px bg-warning m-5 ">
             
            <h2>Insert a date:</h2>
            
            <form name="form1" action="#">
            <ul id="current">
            <li><input type="text" name="text1"></li>
            <li><small>Insert a date in dd/mm/yyyy format</small></li>
            <li class="submit"><input type="submit" value="submit" onclick="validatedate(document.form1.text1)"></li>
            <li>&nbsp;</li>
            
            </ul>
            </form>
            <img src="../../assets/img/calendar.jpg" alt="#"  id="ing" class="img.fluid my-2">
            
            
            </div>
            
      
        </div>
      </div>
    </div>
    
    <div class="m-5"></div>






    <script>
        function validatedate(inputText)
        {
        var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
        // Match the date format through regular expression
        if(inputText.value.match(dateformat))
        {
        document.form1.text1.focus();
        //Test which seperator is used '/' or '-'
        var opera1 = inputText.value.split('/');
        var opera2 = inputText.value.split('-');
        lopera1 = opera1.length;
        lopera2 = opera2.length;
        // Extract the string into month, date and year
        if (lopera1>1)
        {
        var pdate = inputText.value.split('/');
        }
        else if (lopera2>1)
        {
        var pdate = inputText.value.split('-');
        }
        var mm  = parseInt(pdate[0]);
        var dd = parseInt(pdate[1]);
        var yy = parseInt(pdate[2]);
        // Create list of days of a month [assume there is no leap year by default]
        var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
        if (mm==1 || mm>2)
        {
        if (dd>ListofDays[mm-1])
        {
        alert('Invalid date format, please make a format like mm-dd-yyyy');
        return false;
        }
        }
        if (mm==2)
        {
        var lyear = false;
        if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
        {
        lyear = true;
        }
        if ((lyear==false) && (dd>=29))
        {
        alert('Invalid date format, please make a format like mm-dd-yyyy');
        return false;
        }
        if ((lyear==true) && (dd>29))
        {
        alert('Invalid date format! please make a format like mm-dd-yyyy');
        return false;
        }
        }
        }
        else
        {
        alert("Invalid date format! please make a format like mm-dd-yyyy");
        document.form1.text1.focus();
        return false;
        }
        }
        
        
        </script>